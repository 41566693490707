/* ==========================================================================
   STYLE RESETS
   ========================================================================== */

   * { box-sizing: border-box; }

   html {
     /* apply a natural box layout model to all elements */
     box-sizing: border-box;
     background-color: $background-color;
     font-size: 17px;
   
     /* changed these three to change overall font size */
     @include breakpoint($medium) {
       font-size: 11px;
     }
   
     @include breakpoint($large) {
       font-size: 13px;
     /* this one seems to do everything */
     }
   
     @include breakpoint($x-large) {
       font-size: 18px;
     }
   
     -webkit-text-size-adjust: 100%;
     -ms-text-size-adjust: 100%;
   }
   
   /* Remove margin */
   
   body { margin: 0; }
   
   /* Selected elements */
   
   ::-moz-selection {
     color: #fff;
     background: #000;
   }
   
   ::selection {
     color: #fff;
     background: #000;
   }
   
   /* Display HTML5 elements in IE6-9 and FF3 */
   
   article,
   aside,
   details,
   figcaption,
   figure,
   footer,
   header,
   hgroup,
   main,
   nav,
   section {
     display: block;
   }
   
   /* Display block in IE6-9 and FF3 */
   
   audio,
   canvas,
   video {
     display: inline-block;
     *display: inline;
     *zoom: 1;
   }
   
   /* Prevents modern browsers from displaying 'audio' without controls */
   
   audio:not([controls]) {
     display: none;
   }
   
   a {
     color: $link-color;
   }
   
   /* Apply focus state */
   
   a:focus {
     @extend %tab-focus;
   }
   
   /* Remove outline from links */
   
   a:hover,
   a:active {
     outline: 0;
   }
   
   /* Prevent sub and sup affecting line-height in all browsers */
   
   sub,
   sup {
     position: relative;
     font-size: 75%;
     line-height: 0;
     vertical-align: baseline;
   }
   
   sup {
     top: -0.5em;
   }
   
   sub {
     bottom: -0.25em;
   }
   
   /* img border in anchor's and image quality */
   
   img {
     /* Responsive images (ensure images don't scale beyond their parents) */
     max-width: 100%; /* part 1: Set a maximum relative to the parent*/
     width: auto\9; /* IE7-8 need help adjusting responsive images*/
     height: auto; /* part 2: Scale the height according to the width, otherwise you get stretching*/
   
     vertical-align: middle;
     border: 0;
     -ms-interpolation-mode: bicubic;
   }
   
   /* Prevent max-width from affecting Google Maps */
   
   #map_canvas img,
   .google-maps img {
     max-width: none;
   }
   
   /* Consistent form font size in all browsers, margin changes, misc */
   
   button,
   input,
   select,
   textarea {
     margin: 0;
     font-size: 100%;
     vertical-align: middle;
   }
   
   button,
   input {
     *overflow: visible; /* inner spacing ie IE6/7*/
     line-height: normal; /* FF3/4 have !important on line-height in UA stylesheet*/
   }
   
   button::-moz-focus-inner,
   input::-moz-focus-inner { /* inner padding and border oddities in FF3/4*/
     padding: 0;
     border: 0;
   }
   
   button,
   html input[type="button"], // avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio` and `video` controls
   input[type="reset"],
   input[type="submit"] {
       -webkit-appearance: button; /* corrects inability to style clickable `input` types in iOS*/
       cursor: pointer; /* improves usability and consistency of cursor style between image-type `input` and others*/
   }
   
   label,
   select,
   button,
   input[type="button"],
   input[type="reset"],
   input[type="submit"],
   input[type="radio"],
   input[type="checkbox"] {
       cursor: pointer; /* improves usability and consistency of cursor style between image-type `input` and others*/
   }
   
   input[type="search"] { /* Appearance in Safari/Chrome*/
     box-sizing: border-box;
     -webkit-appearance: textfield;
   }
   
   input[type="search"]::-webkit-search-decoration,
   input[type="search"]::-webkit-search-cancel-button {
     -webkit-appearance: none; /* inner-padding issues in Chrome OSX, Safari 5*/
   }
   
   textarea {
     overflow: auto; /* remove vertical scrollbar in IE6-9*/
     vertical-align: top; /* readability and alignment cross-browser*/
   }